import React from 'react'
import Header from '../Header/Header'
import LandingPage from '../LandingPage/LandingPage'
import Mockup from '../Mockup/Mockup'
import Team from '../Team/Team'
import Technology from '../Technology/Technology'

const HomePage = () => {
    return (
        <>
        <Header />
        <LandingPage />
        <Technology />
        <Mockup />
        <Team />
        </>
    )
}

export default HomePage
