import React from 'react'
import Styles from './Header.module.scss';
import Logo from '../../assets/logo.png';
import GitHub from '../../assets/githubBlavk.png';

const Header = () => {
    return (
        <nav className={Styles.navbar}>
            <a href="/">
                <img className={Styles.logo} src={Logo} alt={'MapMe'} />
            </a>
            <div className={Styles.navigation}>
                <a href={'#tech'} className={Styles.links}>
                    <p className={Styles.navigate}>About</p>
                </a>
                <a href={'#team'} className={Styles.links}>
                    <p className={Styles.navigate}>Team</p>
                </a>
                <a rel="noreferrer" href='https://github.com/swaaz/Mapme' target="_blank" className={Styles.links}>
                    <img className={Styles.github} src={GitHub} alt={'GitHub'} />
                </a>
            </div>
        </nav>
    )
}

export default Header
