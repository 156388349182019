import React from 'react';
import Styles from './Technology.module.scss';
import ReactNative from '../../assets/react.png';
import AsyncStorage from '../../assets/database.png';
import GoogleMaps from '../../assets/google.png';
import Emoji from '../../assets/emoji3.png';

const Technology = () => {
    return (
        <section id={'tech'} className={Styles.section}>
            <h2 className={Styles.title}>What’s in here?</h2>
            <div className={Styles.row}>
                <div className={Styles.col}>
                    <div className={Styles.circle}>
                        <img className={Styles.logo} src={ReactNative} alt={'react native'} />
                    </div>
                    <p className={Styles.techStack}>React Native</p>
                    <p className={Styles.description}>Built Using React Native, felt professional that’s it</p>
                </div>

                <div className={Styles.col}>
                    <div className={Styles.circle}>
                        <img className={Styles.logo} src={GoogleMaps} alt={'google maps'} />
                    </div>
                    <p className={Styles.techStack}>Google Maps</p>
                    <p className={Styles.description}>Integrated Google Maps Api for better experience and accurate data</p>
                </div>
                <div className={Styles.col}>
                    <div className={Styles.circle}>
                        <img className={Styles.logo} src={AsyncStorage} alt={'async storage'} />
                    </div>
                    <p className={Styles.techStack}>Async Storage</p>
                    <p className={Styles.description}>An asynchronous, persistent, key-value storage system</p>
                </div>


            </div>
            <p className={Styles.footer}>A basic but not so basic app for newbies who wanna try out some cool tracking stuff !<img className={Styles.emoji} src={Emoji} alt={'Emoji'}/></p>
        </section>
    )
}

export default Technology
