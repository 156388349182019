import React from 'react';
import Styles from './LandingPage.module.scss';
import Vector from '../../assets/vector.png';
import GitHub from '../../assets/githubWhite.png';
import Emoji from '../../assets/emoji4.png';

const LandingPage = () => {
    return (
        <div className={Styles.Section} >
            <div className={Styles.row}>
                <div className={Styles.col}>
                    <h1 className={Styles.title}>MapMe</h1>
                    <p className={Styles.subTitle}>OpenSource Cross Platform App to track wherever you go!</p>
                    <a rel="noreferrer" href='https://github.com/swaaz/Mapme' target="_blank" className={Styles.button}>
                        <div className={Styles.buttonDiv1}>
                            <p className={Styles.buttonTitle}>Open in GitHub</p>
                            <img className={Styles.gitHub} src={GitHub} alt={'GitHub'} />
                        </div>
                    </a>
                </div>
                <img className={Styles.vector} src={Vector} alt={'Vector'} />
            </div>
            <a rel="noreferrer" href='https://github.com/swaaz/Mapme' target="_blank" className={Styles.button}>
                <div className={Styles.buttonDiv2}>
                    <p className={Styles.buttonTitle}>Open in GitHub</p>
                    <img className={Styles.gitHub} src={GitHub} alt={'GitHub'} />
                </div>
            </a>
            <p className={Styles.footer}>You can’t track your girlfriend with this, Sorry! We don't do that here<img className={Styles.emoji} src={Emoji} alt={'Emoji'}/></p>
        </div>
    )
}

export default LandingPage
