import React from 'react'
import Styles from './Team.module.scss';
import Swaaz from '../../assets/swaaz.jpeg';
import Nithin from '../../assets/nithin.jpeg';
import Rahul from '../../assets/rahul.jpeg';
import Emoji1 from '../../assets/emoji1.png';
import Emoji2 from '../../assets/heartemoji.png';

const Team = () => {
    return (
        <section id={'team'} className={Styles.section}>
            <h2 className={Styles.title}>Team!</h2>
            <div className={Styles.row}>
                <div className={Styles.col}>
                    <a rel="noreferrer" target="_blank" href='https://github.com/nithinmahendran'>
                        <div className={Styles.circle}>
                            <img className={Styles.logo} src={Nithin} alt={'nithin'} />
                        </div>
                    </a>
                    <p className={Styles.techStack}>Nithin Mahendran</p>
                    <p className={Styles.description}>Flutter Developer</p>
                </div>

                <div className={Styles.col}>
                    <a rel="noreferrer" target="_blank" href='https://github.com/swaaz'>
                        <div className={Styles.circle}>
                            <img className={Styles.logo} src={Swaaz} alt={'swaaz'} />
                        </div>
                    </a>
                    <p className={Styles.techStack}>Swasthik Shetty</p>
                    <p className={Styles.description}>React Developer</p>
                </div>

                <div className={Styles.col}>
                    <a rel="noreferrer" target="_blank" href='https://github.com/RahulRao23'>
                        <div className={Styles.circle}>
                            <img className={Styles.logo} src={Rahul} alt={'rahul'} />
                        </div>
                    </a>
                    <p className={Styles.techStack}>Rahul Rao</p>
                    <p className={Styles.description}>Flutter Developer</p>
                </div>

            </div>
            <p className={Styles.footer}> That’s all folks! <img className={Styles.emoji} src={Emoji1} alt={'Emoji'}/></p>
            <div className={Styles.footerBar}>
                <p className={Styles.footerMessage}>Made on earth with <img className={Styles.emoji} src={Emoji2} alt={'Emoji'}/></p>
            </div>
        </section>
    )
}

export default Team
