import Styles from './App.module.scss';
import HomePage from './components/HomePage/HomePage';

function App() {
  return (
    <div className={Styles.App}>
      <HomePage />
    </div>
  );
}

export default App;
