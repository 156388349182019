import React from 'react';
import Styles from './Mockup.module.scss';
import Mockup1 from '../../assets/Maockup1.png';
import Mockup2 from '../../assets/Maockup2.png';
import Mockup3 from '../../assets/Maockup3.png';
import Mockup4 from '../../assets/Maockup4.png';
import Emoji from '../../assets/emoji2.png';

const Mockup = () => {
    return (
        <section className={Styles.section}>
            <h2 className={Styles.title}>Mockups Necessary?</h2>
            <div className={Styles.grids}>
                <div className={Styles.grid1}>
                    <img className={Styles.image} src={Mockup3} alt={'Mockup'} />
                </div>
                <div className={Styles.grid2}>
                    <img className={Styles.image} src={Mockup1} alt={'Mockup'} />
                </div>
                <div className={Styles.grid1}>
                    <img className={Styles.image} src={Mockup2} alt={'Mockup'} />
                </div>
                <div className={Styles.grid2}>
                    <img className={Styles.image} src={Mockup4} alt={'Mockup'} />
                </div>
            </div>
            <p className={Styles.footer}>Still Searching for a download link? Well, We can’t afford Google’s API for everyone!<img className={Styles.emoji} src={Emoji} alt={'Emoji'}/></p>
        </section>
    )
}

export default Mockup
